import React from "react"
import { Section, SectionWrapper, SubTitle } from "../../core/commonExports"
import {Button} from "../../core/Button"

const FooterSection = ({handleGetQuoteClick}) => {
  return (
    <Section background="rgba(37, 39, 123, 0.04)">
      <SectionWrapper>
        <SubTitle fontSize="36px" lineHeight="72px" mfontSize="22px" mlineHeight="32px" color="#2A2B7F" style={{textTransform: "uppercase", marginBottom: "58px", textAlign: "left"}}>
          Always have a backup plan! Insure your commercial property against burglary, fire, flood and 15+ threats today!
        </SubTitle>
        <Button wide onClick={handleGetQuoteClick} />
      </SectionWrapper>
    </Section>
  )
}

export default FooterSection